import React from "react";
import { Link } from "gatsby";
import {Mark50} from "../Logo/Logos";

const styles = require("./Footer.module.scss");

interface Props {}

const Footer: React.FunctionComponent<Props> = () => {
	return (
		<React.Fragment>
			<footer className={styles.Footer}>
				<div className={styles.Logo}>
					{Mark50}
				</div>

				<div>
					<div className={styles.SubMenu}>
						<h6>Prestavi</h6>
						<div>
							<Link to="/">Home</Link>
							<Link to="/pricing">Pricing</Link>
							<Link to="/contact-us">Contact us</Link>
							<a href="https://developers.prestavi.com">Developers</a>
							<a href="/security">Security</a>
							{/*<a href="https://app.prestavi.com/signup">Sign up</a>*/}
						</div>
					</div>

					<div className={styles.SubMenu}>
						<h6>Legal</h6>
						<div>
							<Link to="/legal/gdpr">GDPR</Link>
							<Link to="/legal/hipaa">HIPAA</Link>
							<Link to="/legal/privacy">Privacy Policy</Link>
							<Link to="/legal/terms">Terms of Service</Link>
						</div>
					</div>
					<div className={styles.SubMenu}>
						<h6>Customers</h6>
						<div>
							<a href="https://app.prestavi.com/login">Log in</a>
						</div>
					</div>
				</div>
				</footer>


			<div className={styles.Copyright}>
				Copyright © 2022 Prestavi, Inc. All Rights Reserved.
			</div>
		</React.Fragment>

	);
};

export default Footer;
